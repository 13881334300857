export default {
  name: "EstimateCollectability",
  data() {
    return {
      property: {
        animation: {
          estimateCollectability: {
            isLoading: false,
          },
        },
        listElement: {
          estimateCollectability: {
            downloading: false,
            message: "",
          },
        },
      },
      dataForm: {
        periode: "",
        office: "",
        estimateType: "",
      },
      table: {
        data: {
          estimateCollectability: [],
        },
      },
      options: {
        office: [],
      },
    };
  },
  methods: {
    getReferenceOffice() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "office",
          params: {
            officeName: "",
            page: 0,
          },
        })
        .then((resp) => {
          this.options.office = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeId;
            this.options.office.push({ text, value });
          });
        });
    },
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.estimateCollectability.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DOWNLOADABLE_ESTIMATE_COLLECTABILITY",
          fileType: fileType,
          officeCode: this.dataForm.office,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Estimate_Collectability.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
      } finally {
        this.property.animation.estimateCollectability.downloading = false;
      }
    },
    async getReportEstimatedCollectability() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.estimateCollectability.isLoading = true;
      this.property.listElement.estimateCollectability.downloading = true;
      this.table.data.estimateCollectability = [];
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "report/estimate-collectability",
          payload: {
            officeId: this.dataForm.office,
          },
        });
        if (resp.data.code === "SUCCESS") {
          setTimeout(() => {
            this.table.data.estimateCollectability = resp.data.data;
          }, 1000);
        } else {
          this.property.listElement.estimateCollectability.message =
            resp.data.message;
        }
      } catch (error) {
        this.table.data.estimateCollectability = [];
        this.property.listElement.estimateCollectability.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        setTimeout(() => {
          this.property.animation.estimateCollectability.isLoading = false;
          this.property.listElement.estimateCollectability.downloading = false;
        }, 1000);
      }
    },
  },
  mounted() {
    this.getReferenceOffice();
    this.property.listElement.estimateCollectability.message =
      "No Data Processed";
  },
};
